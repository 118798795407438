<template>
  <div
    class="formdata"
    style="padding: 20px;"
  >
    <!-- <div class="imgStyle">
      <el-image
        :src="require('@/assets/expired.png')"
      />
    </div> -->
    <el-form
      ref="form"
      :model="form"
      label-width="120px"
    >
      <div class="title">
        <div class="titleType">
          处方信息
        </div>
      </div>
      <el-row :gutter="60">
        <el-col :span="10">
          <el-row>
            <el-form-item label="医生姓名:">
              {{ form.doctorName }}
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="处方号:">
              {{ form.id }}
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="就诊科室:">
              {{ form.deptName }}
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :span="10">
          <el-row>
            <el-form-item label="开方时间:">
              {{ form.createTime }}
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="审方时间:">
              {{ form.auditTime }}
            </el-form-item>
          </el-row>
        </el-col>
      </el-row>
      <div class="title">
        <div class="titleType">
          患者信息
        </div>
      </div>
      <el-row :gutter="60">
        <el-col :span="10">
          <el-row>
            <el-form-item label="患者名称:">
              {{ form.patientName }}
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="性别:">
              {{ sexDic[form.patientSex] }}
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :span="10">
          <el-row>
            <el-form-item label="年龄:">
              {{ form.patientAge }}岁
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="主诉:">
              {{ form.chiefComplaint }}
            </el-form-item>
          </el-row>
        </el-col>
      </el-row>

      <div class="title">
        <div class="titleType">
          诊断信息
        </div>
      </div>
      <el-form-item label="">
        {{ form.diagnosisName }}
      </el-form-item>
      <div class="title">
        <div class="titleType">
          处方药品信息
        </div>
      </div>
      <div style="font-weight:bold;font-size: 20px;margin-left: 50px;">
        Rp
      </div>
      <div class="drugList">
        <div
          v-for="item in form.medicals"
          :key="item.id"
        >
          <div style="margin-left:50px;margin-bottom:10px;display: flex;">
            <div class="medical">
              {{ item.medicalName }}
            </div>
            <!-- <div class="medical">
              {{ item.specs }}
            </div> -->
            <div class="medical">
              {{ item.specsPacking }}
            </div>
            <span>{{ item.quantity }}</span>
          </div>
          <el-form-item
            label="用法用量:"
          >
            <span class="medical">{{ item.medicalUsage }}</span>
            <span class="medical">{{ item.frequency }}</span>
            <span class="medical">每次{{ item.consumption }}{{ item.minUseUnit }}</span>
            <span class="medical">连用{{ item.medicationDays }}天</span>
            <span class="medical">{{ item.entrust }}</span>
          </el-form-item>
        </div>
      </div>
      <div class="imgsty">
        <el-form-item
          label="医师签名:"
        >
          <img
            v-if="form.doctorTrustSignatureUrl"
            style="width: 100px; height: 50px;"
            :src="form.doctorTrustSignatureUrl"
          >
        </el-form-item>
        <el-form-item
          label="药师签名:"
        >
          <img
            v-if="form.auditTrustSignatureUrl"
            style="width: 100px; height: 50px;"
            :src="form.auditTrustSignatureUrl"
          >
        </el-form-item>
      </div>
      <template v-if="form.auditStatus==='FAIL'">
        <div
          class="title"
        >
          <div class="titleType">
            处方审核失败原因
          </div>
        </div>
        <el-form-item>
          {{ form.auditReason }}
        </el-form-item>
      </template>
      <div class="btn">
        <el-button @click="$router.back()">
          返回
        </el-button>
        <el-button
          v-if="form.auditSignStatus==='SUCCESS'&&form.auditStatus==='SUCCESS'&&form.createSignStatus==='SUCCESS'&&form.signatureStatus==='SUCCESS'"
          type="primary"
          @click="showChufang"
        >
          查看电子处方
        </el-button>
        <el-button
          v-else
          disabled
          type="info"
        >
          查看电子处方
        </el-button>
      </div>
    </el-form>

    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <img
        width="85%"
        :src="PDFUrl"
        alt="电子处方"
      >
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogVisible = false"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { sexDic } from '@/utils/component/publicList';

  export default {
    name: 'Details',
    data() {
      return {
        sexDic:sexDic, // 性别字典
        PDFUrl:'',
        form:{

        },
        summary:{

        },
        dialogVisible: false
      };
    },
    computed: {

    },
    watch: {},
    mounted() {
      this.getPrescriptionDetail();
    },
    methods: {
      // 显示电子处方对话框
      showChufang() {
        this.dialogVisible = true;
      },
      // 处方详情
      getPrescriptionDetail() {
        const param = {
          id: this.$route.query.id
        };
        this.$api.prescriptionDetailById(param).then(res => {
          this.form = res.data;
          this.PDFUrl = this.form.signaturePdfImgUrl;
          console.log(this.PDFUrl, '8888888888888888888888888');
          console.log(this.form, '9999999999999999999999999999999999999999999999');
        });
      },
      // 查询问诊小结
      querySummaryDetail(data) {
        const param = {
          consultationOrderNo:data.consultationOrderNo,
          consultationType:data.consultationType
        };
        this.$api.summaryDetail(param).then(res => {
          this.summary = res.data;

        });
      },
      // 查询电子处方详情
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(() => {
            done();
          })
          .catch(() => {});
      }
    }

  };
</script>

<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}
.labelStyle { margin-right: 10px; }

.title {
  height: 40px;
  margin-bottom: 10px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
}

.btn {
  margin-left: 60px;
}

 .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.flex {
  display: flex;
}

.signImg{
  display: flex;
  margin-left:100px;
  margin-top:20px;
}
.drugList{
    margin-left: 60px;
}

.imgStyle {
  position: absolute;
  margin-left: 150px ;
}
.imgsty{
   margin-left:50px;
  display: flex;
  align-items: center;
}
.medical{
  margin-right:15px;
}
.title2 {
  height: 40px;
  margin-bottom: 10px;
  line-height: 40px;
  text-align: center;
  background-color: #e8e8e8;
}
</style>
